.table-container {
  height: 54.4vh; /* Adjust this value as needed */
  overflow: auto;
  border-radius: 10px !important;
}

.table-container .ant-table {
  max-width: 100%;
  height: 100%;
}

.table-container .ant-table-thead > tr > th {
  border: 2px solid #f5f4f4 !important;
  /* background-color: rgb(193, 230, 255); */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  color: #000000 !important;
  text-align: center;
}

.table-container .ant-table-tbody > tr > td {
  border: 1px solid #f5f4f4 !important;
}

.table-container .ant-table-cell {
  white-space: nowrap;
  border: 1px solid black !important;
}

.table-container .ant-table-body {
  max-height: 100%;
  overflow-y: auto;
}

::-webkit-scrollbar {
  height: 10px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
